import MainLayout from '../../common/mainLayout';
import "./environmentStyle.css";
import { EditOutlined } from '@ant-design/icons';
import { Typography, Button, Row, Col, Modal, Form, Input, notification} from 'antd';
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import { useState } from 'react';
import { createEnvironment } from '../../redux/actions/environmentActions';

const {Paragraph, Title} = Typography;

const EnvironmentSettings = () =>{

    const currentEnv = useSelector((state) => get(state, "envState.currentEnvironment.name"));
    const currentEnvKey = useSelector((state) => get(state, "envState.currentEnvironment.environmentKey"));
    const organizationId = useSelector((state) => get(state, "envState.currentEnvironment.organization"))
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [envName, setEnvName]=useState("");
    const dispatch = useDispatch();

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const handleClick = (envName: string) =>{
      
      dispatch(createEnvironment(envName)).then((res: any)=>{
        setIsModalOpen(false);
        notification.success({
          message: `${envName} environment created!`,
        })
      }).catch(()=>{
        notification.error({
          message: `Could not create ${envName} environment!`,
        })
      })
      
      
    }

    return(
        <>
        <MainLayout>

        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
         <div>
          <div className='title3 title-semibold' >Environment Settings</div>
          <div className='title6' style={{marginBottom:"40px"}}></div>
         </div>
         <Button type='primary' size='large' onClick={showModal}> + Add Environment</Button>
         <Modal
           centered
           className="feature-modal invite-modal"
           visible={isModalOpen}
           onCancel={handleCancel}
           footer={null}
           closable={false}
         >
         <div className="modal-card">
          <div className='invite-header'>
            <h4 className="title4">Create Environment</h4>
          </div>
          <div>
            <div className='input-group'>
              <div className="inputs">
                <Input placeholder="Environment name" onChange={(e)=>setEnvName(e.target.value)} />
              </div>
              <Button type="primary" size="large" style={{marginTop:"10px"}}onClick={()=>handleClick(envName)}>Add</Button>
            </div>
          </div>
         </div>
        </Modal>
        </div>
        <Row>
            <Col xs={24} sm={12}>
            <div className="environmentContainer">
             <div className="title4 title-semibold">{currentEnv}</div>
             <div style={{display:"flex", color:"grey"}}>
                 {/* <div style={{fontSize:"12px"}}>Edit </div>
                  <div> <EditOutlined  /></div> */}
             </div>
            </div>
            <div style={{marginBottom:"30px"}}>Environment key is to used by launchbees SDKs. If you are not sure, you can read of guide and integration steps. </div>
            <div style={{backgroundColor:"#f8f6f6", display:"flex",justifyContent:"space-between", alignItems:"center", marginBottom:"30px"}}>
                <div style={{padding: "10px", color:"grey"}}>Environment Key</div>
                <Paragraph copyable style={{marginBottom:"0px", paddingRight:"10px", fontSize:"13px", wordBreak:"break-all"}}>{`${organizationId}.${currentEnvKey}`}</Paragraph>
            </div>
            <div>
                <div>
                  <Title level={5}>Delete this Environment</Title>
                  <div>You will begin the process of deleting this environment.This is a permanent action & can't be undone. Contact your relationship manager to proceed further.</div>
                </div>
                {/* <div style={{color:"#fa64aa", fontSize:"13px", fontWeight:"700"}}>Delete</div> */}
            </div>
            </Col>
        </Row>
        </MainLayout>
        </>
    );
}

export default EnvironmentSettings;