import axios from 'axios';
import * as TYPES from '../redux/constants/actionTypes'
import { notification } from 'antd';
import get from 'lodash/get';
import head from 'lodash/head';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL + "/" + process.env.REACT_APP_API_VERSION;

const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  function (config : any) {
    return config;
  },
  function (error : any) {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response : any) {
    return response;
  },
  function (err : any) {
    console.log(err.response);
    if (!err.response) {
      // notification.error({
      //   message: 'Something Went Wrong',
      //   description: 'Please Check Your Internet Connection',
      // });
    } else {
      import('../redux/store').then((persistStore) => {
        const { store } = persistStore.default;
        const state = store.getState();

        console.log('Error>>>', err);

        if (err.response.status === 401) {
          store.dispatch({ type: TYPES.USER_LOG_OUT });
          return;
        } else if (err.response.status === 400) {
          notification.error({
            message: get(err, 'response.data.message', 'Something Went Wrong'),
          });
        } else if (err.response.status === 422) {
          notification.error({
            message: get(err, 'response.data.message', 'Something Went Wrong'),
          });
        } else {
          let messages = get(err, 'response.data.message');
          //messages = get(head(messages), 'messages');

          notification.error({
            message: 'Something Went Wrong',
            description: messages
          });
        }
      });
    }
    return Promise.reject(err);
  },
);

export default instance;

export function setAccessToken(axiosInstance: any, accessToken: string): void {
  axiosInstance.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${accessToken}`;
}

// export function setEnvironment(axiosInstance: any, env: string): void {
//   axiosInstance.defaults.headers.common[
//     'Authorization'
//   ] = `Bearer ${accessToken}`;
// }
