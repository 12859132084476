import { AxiosInstance } from "axios";
import { GET_ORG_CONFIG } from "../../remote/apis/apiRoutes";
import { IEntityResponse } from "../../remote/types/response";
import * as TYPES from '../constants/actionTypes'
import { IOrgConfigResponse } from "../../remote/types/organizationTypes";



export const loadOrgConfig = (): any => (dispatch: any, getState: any, { api }: { api: AxiosInstance }): any => {

    api.get<IEntityResponse<IOrgConfigResponse>>(GET_ORG_CONFIG).then(res => {
        let data = res.data
        let orgConfig = data.data

        dispatch({
            type: TYPES.LOAD_ORG_CONFIG,
            payload: orgConfig
    })
    }).catch(err => {
        console.log(err)
    })



}