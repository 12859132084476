import * as UserActions from '../actions/userActions';
import instance, { setAccessToken } from '../../remote/axios'
import get from 'lodash/get';
import * as TYPES from '../constants/actionTypes'
import { IUserResponse } from '../../remote/types/response';
export interface IUser {
  id: number;
  name: string;
  phone?: string;
  email?: string;
  profilePicture?: string;
  role?: string;
}

export interface IUserState {
  id?: number;
  accessToken?: string;
  user?: IUserResponse;
}

function initializeState(): IUserState {
  return {};
}

export function reduce(
  state: IUserState = initializeState(),
  action: any,
): IUserState {
  switch (action.type) {

    case TYPES.USER_LOGGED_IN:
      let { id, accessToken } = action.payload;
      return { id: id, accessToken: accessToken, user: action.payload };

    case TYPES.USER_LOG_OUT:
      return initializeState();

    case 'persist/REHYDRATE':
      let accessTokenRefresh = get(action, 'payload.userState.accessToken', null)
      if (accessTokenRefresh) {
        setAccessToken(instance, accessTokenRefresh)
      }
      return get(action, 'payload.userState', initializeState());

    default:
      return state;
  }
}
