import AppRouter from './AppRouter';
import perisistStore from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import launchbees from 'launchbees-js-sdk'
import { LaunchbeesProvider } from 'launchbees-js-sdk/dist/react'
import mixpanel from 'mixpanel-browser';



// Styles
import './styles/css/antd.css';
import './styles/App.scss';
import { useEffect } from 'react';
import Anlaytics from './analytics/analytics';

const { store, persistor } = perisistStore;

function App() {

  // Add mixpanel init
  useEffect(() => {
      Anlaytics.InitAnalytics()
  }, []);


  return (
    <Provider store={store}>
    <LaunchbeesProvider launchbees={launchbees} config={{
          environmentKey: process.env.REACT_APP_LB_ENV_KEY!,
    }}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="app-container">
          <AppRouter />
        </div>
      </PersistGate>
      </LaunchbeesProvider>
    </Provider>
  );
}

export default App;