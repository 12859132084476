import * as UserActions from '../actions/userActions';
import instance, { setAccessToken } from '../../remote/axios'
import get from 'lodash/get';
import * as TYPES from '../constants/actionTypes'
import { IUserResponse } from '../../remote/types/response';
import { IProductAreaResponse, ITagResponse } from '../../remote/types/organizationTypes';

export interface IOrgState {
  events: string[];
  productAreas: IProductAreaResponse[];
  tags: ITagResponse[];
}

function initializeState(): IOrgState {
  return {
    events: [],
    productAreas: [],
    tags: []
  };
}

export function reduce(
  state: IOrgState = initializeState(),
  action: any,
): IOrgState {
  switch (action.type) {

    case TYPES.LOAD_ORG_CONFIG:
      return {
        ...state,
        ...action.payload
      };

    case 'persist/REHYDRATE':
      return get(action, 'payload.orgState', initializeState());

    default:
      return state;
  }
}
