import { AxiosInstance } from "axios";
import { MEMBERS, MY_DETAILS } from "../../remote/apis/apiRoutes";
import { setAccessToken } from "../../remote/axios";
import { IEntityResponse, IUserResponse } from "../../remote/types/response";
import { IUser } from "../reducers/userReducer";
import * as TYPES from '../constants/actionTypes'

export const login = (phone: number, otp: string): any => (dispatch: any, getState: any, { api }: any): any => {

    // Call APIs here and get response
    // api.post('/login', {
    //     phone, otp
    // }).then(res => {

    // })

    const id = 12;
    const accessToken = 'xyz';
    const user: IUser = {
        id: 12,
        name: 'Sample User'
    }

    dispatch({
        type: TYPES.USER_LOGGED_IN,
        payload: { id, accessToken, user },
    });;
}

export const loginWithToken = (token: string): any => (dispatch: any, getState: any, { api }: { api: AxiosInstance }): any => {

    setAccessToken(api, token)

    api.get<IEntityResponse<IUserResponse>>(MY_DETAILS).then(res => {
        let data = res.data
        let user = data.data

        dispatch({
            type: TYPES.USER_LOGGED_IN,
            payload: { ...user, accessToken: token },
        });

    }).catch(() => {
        dispatch({
            type: TYPES.USER_LOG_OUT,
        });
    })
    // Call APIs here and get response
    // api.post('/login', {
    //     phone, otp
    // }).then(res => {

    // })


}

export const logout = (): any => (dispatch: any, getState: any, { api }: any): any => {
    dispatch({
        type: TYPES.USER_LOG_OUT,
    });
}
export const getMembers = (): any => {

    return (dispatch: any, getState: any, { api }: { api: AxiosInstance }): Promise<IUserResponse[]> => {

        return api.get<IUserResponse[]>(MEMBERS).then(res => {
            let data = res.data
            let users = data
            return users
        })


    }
}