import * as EnvActions from '../actions/environmentActions';
import get from 'lodash/get';
import * as TYPES from '../constants/actionTypes'

export interface IEnvironment {
  _id: string;
  name: string;
  environmentKey: number;
  organization: number;
  features: object[];
}

export interface IFeatureState {
  _id: string;
  featureKey: string;
  isEnabled: boolean;

}

export interface IEnvState {
  currentEnvironment?: IEnvironment;
  allEnvironments?: IEnvironment[]
}

function initializeState(): IEnvState {
  return {};
}

export function reduce(
  state: IEnvState = initializeState(),
  action: any,
): IEnvState {
  switch (action.type) {

    case TYPES.SWITCH_ENVIRONMENT:
      let { environment } = action.payload;
      return { ...state, currentEnvironment: environment };

    case TYPES.GET_ENVIRONMENTS:
      let { environments } = action.payload;
      return { ...state, allEnvironments: environments }

    default:
      return state;
  }
}
