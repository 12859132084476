import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom'
import { loginWithToken } from '../../../redux/actions/userActions';
import {Navigate} from 'react-router-dom';

function LoginSuccess() {

    let [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const [redirctTo, setRedirctTo] = useState(false);
    
    useEffect(() => {
        //load user details
        const token = searchParams.get("token")
        
        if(token) dispatch(loginWithToken(token));
        
        // redirect user to home page after login
        setRedirctTo(true)
        
        return () => {
            
        }
    }, [])

    if(redirctTo){
        return <Navigate to="/" />
    } 
      
    return (
        <Spin spinning={true}>
            <div>Logging you in</div>
        </Spin>
        
        
    )
}

export default LoginSuccess