import get from 'lodash/get';
import instance from "../../remote/axios"
import { AxiosInstance } from "axios";
import { GET_ENVIRONMENTS, GET_ENVIRONMENT_BY_ID, UPDATE_FEATURE_STATE} from "../../remote/apis/apiRoutes";
import { IEntityResponse, IFeatureResponse } from "../../remote/types/response";
import * as TYPES from '../constants/actionTypes'
import { replaceMultiple } from '../../remote/utilities/helper'

export const getAllEnvironments = () : any => (dispatch: any, getState: any, { api } : {api: AxiosInstance} ): any => {

    api.get<IEntityResponse<IFeatureResponse>>(GET_ENVIRONMENTS)
    .then((result) => {            
        let envList = get(result, 'data.data')
        dispatch({
            type: TYPES.GET_ENVIRONMENTS, 
            payload: {environments: envList}
        })
    })
    .catch((error: any) => {
        throw(error)
    });
}

export const getFeatureStatusByEnvironment = (envId: string) => {
    let URL = GET_ENVIRONMENT_BY_ID.replace('{environment-id}', envId);
    return instance 
        .get<IEntityResponse<IFeatureResponse>>(URL)
        .then((result) => {
            let features = get(result, 'data.data.features')
            return features;
        })
        .catch((error: any) => {
            throw(error)
        });
}

export const switchEnvironment = (envId: string): any => (dispatch: any, getState: any, { api } : {api: AxiosInstance} ): any => {
    let URL = GET_ENVIRONMENT_BY_ID.replace('{environment-id}', envId);
    api.get<IEntityResponse<IFeatureResponse>>(URL)
    .then(res => {
        let env = get(res, 'data.data')
        dispatch({
            type: TYPES.SWITCH_ENVIRONMENT,
            payload: {environment: env}
        })
    })
    .catch((error) => {
        throw(error)
    })
}

export const updateFeatureState = (envId: string, featureId: string, featureDetails: any) : any => {
    
    let URL = replaceMultiple(UPDATE_FEATURE_STATE, {
        '{environment-id}': envId,
        '{feature-id}': featureId
    });
    return instance 
        .put<IEntityResponse<IFeatureResponse>>(URL, featureDetails)
        .then((result) => {
            return result;
        })
        .catch((error: any) => {
            throw(error)
        });
}

export const createEnvironment = (name: string): any => (dispatch: any, getState: any, { api } : {api: AxiosInstance} ): any => {
    return api.post<IEntityResponse<IFeatureResponse>>(GET_ENVIRONMENTS, {name:name})
    .then((result) => {
        dispatch(getAllEnvironments())
        return result
    })
}

// Add function createEnvironments(name)
// data = { name : "xyz" }

// api.post("/environment", {name : name})