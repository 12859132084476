
// Main Switch Navigation Routes
export const LOGIN_SCREEN = "/login";
export const SIGNUP_SCREEN = "/signup";
export const COMPLETE_PROFILE_SCREEN = "/complete-profile"
export const NEW_LOGIN_SCREEN = "/new-login";
export const FORGOT_SCREEN = "/forgot-password";
export const LOGIN_SUCCESS = "/loginsuccess"

// Main Stack Navigator Routes
export const HOME_SCREEN = "/";
export const ENVIRONMENT_SETTINGS = "/environment-settings"
export const ADD_FEATURE_SCREEN = "/add-feature"
export const FEATURE = "/feature/:id"
export const FEATURE_TARGETING = "/feature/targeting/:id"
export const ORGANISATIONS_SCREEN = "/organisations"
export const ORGANISATION_ACTIVITY_SCREEN = "/organisations/:orgId"
export const MY_ACCOUNT_SCREEN = "/my-account";
export const ROLES = "/roles"
