import { Button, Layout, Menu, Avatar, Popover, Spin } from "antd";
import { Link, matchPath, NavLink, useLocation } from "react-router-dom";
import ROUTES from "../../router";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllEnvironments, switchEnvironment } from "../../redux/actions/environmentActions";
import { logout } from '../../redux/actions/userActions'

// Images
import { LogoDark } from "../../assets/logo";
import arrowIcon from "../../assets/icons/up-down.svg";
import featureIcon from "../../assets/icons/feature.svg";
import organisationsIcon from "../../assets/icons/organisations.svg";
import settingIcon from "../../assets/icons/setting.svg";
import planIcon from "../../assets/icons/plan.svg";
import docsIcon from "../../assets/icons/docs.svg";
import guideIcon from "../../assets/icons/guide.svg";
import questionIcon from "../../assets/icons/question.svg";
import arrowRight from "../../assets/icons/caret-right.svg";

// styles
import "./styles.scss";
import { BellFilled, LogoutOutlined, PlayCircleOutlined, PlaySquareOutlined } from "@ant-design/icons";
import { useFlags } from "launchbees-js-sdk/dist/react";
import { on } from "events";

const { Header, Sider, Content } = Layout;
interface MainLayoutScreenProps {
  children: any;
}

function MainLayout(props: MainLayoutScreenProps) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const flags = useFlags(['end_org_view', 'plans'])

  useEffect(() => {
    dispatch(getAllEnvironments());
  }, []);

  const handleSwitchEnvironment = (id: string) => {
    if (id) dispatch(switchEnvironment(id));
  };

  const currentEnv = useSelector((state) => get(state, "envState.currentEnvironment"));
  const allEnvironments = useSelector((state) => get(state, "envState.allEnvironments"));
  const userName = useSelector((state) => get(state, "userState.user.name"));
  const userNameInitials = useSelector((state) => get(state, "userState.user.name[0]"));
  const profilePicture = useSelector((state) => get(state, "userState.user.profilePicture"));
  const location = useLocation()
  const [selectedKeys, setSelectedKeys] = useState(["1"])

  const getCurrentEnvName = () => {
    if (currentEnv) {
      return get(currentEnv, "name");
    } else {
      // set dev as default env
      if (allEnvironments) {
        let devEnv = allEnvironments[0];
        handleSwitchEnvironment(get(devEnv, "_id"));
      }
    }
  };

  const onLogout = () => {
    dispatch(logout())
  }

  let pathArray = [
    { path: ROUTES.ENVIRONMENT_SETTINGS, key: "2" },
    { path: ROUTES.ORGANISATIONS_SCREEN, key: "3" },
    { path: ROUTES.ORGANISATION_ACTIVITY_SCREEN, key: "3" },
    { path: ROUTES.ROLES, key: "4" },
    { path: ROUTES.HOME_SCREEN, key: "1" }
  ];

  useEffect(() => {

    for (let i = 0; i < pathArray.length; i++) {
      var isMatched = matchPath(pathArray[i].path, location.pathname)
      if (isMatched) {
        setSelectedKeys([`${pathArray[i].key}`])
        break;
      }
    }
  }, [location])


  const content = (
    <div className="environment-popup">
      <div className="head">
        <h4 className="title4">Environments</h4>
        {/* <Button type="primary" className='btn-plus'>
          Add Environment <PlusCircleOutlined />
        </Button> */}
      </div>
      <div className="environment-list">
        {allEnvironments?.map((environment: any, i: any) => {
          return (
            <div onClick={() => handleSwitchEnvironment(get(environment, "_id"))} key={i}>
              <Link to="">
                {get(environment, "name")} <img src={arrowRight} alt="left-arrow" />
              </Link>
            </div>
          );
        })}

        {/* <Link to="">
          Meta <img src={arrowRight} alt="left-arrow" />
        </Link>
        <Link to="">
          Meta2 <img src={arrowRight} alt="left-arrow" />
        </Link>
        <Link to="">
          Meta3 <img src={arrowRight} alt="left-arrow" />
        </Link> */}
      </div>
    </div>
  );

  return (
    <Layout>
      <Sider className="theme-sidebar">
        <div className="sidebar-first">
          <div className="logo-sec">
            <LogoDark className="logo" />
          </div>
          <div className="download-btn">
            <Spin spinning={loading}>
              <Popover content={content} trigger={["click"]} placement="bottomLeft">
                <Button>
                  {getCurrentEnvName()}
                  <Avatar src={arrowIcon} className="icon-style" size={18} />
                </Button>
              </Popover>
            </Spin>
          </div>

          <Menu
            mode="inline"
            selectedKeys={selectedKeys}
            items={[
              {
                key: "1",
                icon: <Avatar src={featureIcon} shape="square" className="icon-style" size={18} />,
                label: <Link to={ROUTES.HOME_SCREEN}>Features</Link>,
              },
              {
                key: "2",
                icon: <Avatar src={settingIcon} shape="square" className="icon-style" size={18} />,
                label: (
                  <Link to={ROUTES.ENVIRONMENT_SETTINGS}>Settings</Link>
                ),
              },
              ...flags.end_org_view.isEnabled ? [{
                key: "3",
                icon: <Avatar src={organisationsIcon} shape="square" className="icon-style" size={18} />,
                label: (
                  <Link to={ROUTES.ORGANISATIONS_SCREEN}>Organisations</Link>
                ),
              }] : [],
              ...flags.plans.isEnabled ? [{
                key: "4",
                icon: <Avatar src={planIcon} shape="square" className="icon-style" size={18} />,
                label: (
                  <Link to={ROUTES.ROLES}>Plans</Link>
                ),
              }] : [],
            ]}
          ></Menu>
        </div>
        <div className="user-btn">
          <div className='request'>
            {/* <BellFilled /> Approval Request */}
          </div>
          {/* <div>
            <h3> Interesting fact of the day </h3>
          </div> */}
          <NavLink className="ant-btn" to={ROUTES.MY_ACCOUNT_SCREEN}>
            <div>
              {profilePicture ? <Avatar src={profilePicture} size={40} /> : <Avatar>{userNameInitials}</Avatar>}
              <span className="name">{userName}</span>
            </div>
            <Avatar src={arrowIcon} className="icon-style" size={18} />
          </NavLink>
        </div>
      </Sider>
      <Layout className="site-layout theme-layout-main">
        <Header className="horizontal-navigation">
          <Menu
            mode="horizontal"
            // defaultSelectedKeys={['1']}
            items={[
              {
                key: "1",
                icon: <a target="_blank" href="https://docs.launchbees.com/launchbees-overview/what-is-launchbees"> <Avatar src={guideIcon} shape="square" className="icon-style" size={15} /></a>,
                label: "User Guide",
              },
              {
                key: "2",
                icon: <a target="_blank" href="https://docs.launchbees.com/sdk-integration-guide/using-launchbees-react-sdk"><Avatar src={docsIcon} shape="square" className="icon-style" size={15} /></a>,
                label: "Integration",
              },
              {
                key: "3",
                icon: <a target="_blank" href="https://docs.launchbees.com/launchbees-overview/what-is-launchbees">  <Avatar src={questionIcon} shape="square" className="icon-style" size={15} /></a>,
                label: "FAQ",
              },
              {
                key: "4",
                // icon: <Avatar src={questionIcon} shape="square" className="icon-style" size={15} />,
                label: "",
              },
              {
                key: "5",
                icon: <LogoutOutlined />,
                label: "Logout",
                onClick: onLogout
              },
            ]}
          />
        </Header>
        <Content className="site-content">{props.children}</Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
