import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Switch, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import MainLayout from "../../common/mainLayout";
import "./styles.scss";


interface IDataType {
    feature: string,
    featureKey: string,
    basic: boolean,
    pro: boolean,
    enterprise: boolean,
}

const { Title, Text, Paragraph } = Typography;

const Roles = () => {

    const data: IDataType[] = [
        {
            feature: "Feature Integration",
            featureKey: "chat_feature",
            basic: true,
            pro: true,
            enterprise: true,
        },
        {
            feature: "Feature Name",
            featureKey: "chat_feature",
            basic: true,
            pro: true,
            enterprise: true,
        }, {
            feature: "CI/CD",
            featureKey: "chat_feature",
            basic: true,
            pro: true,
            enterprise: true,
        }, {
            feature: "Teams",
            featureKey: "chat_feature",
            basic: false,
            pro: true,
            enterprise: true,
        }, {
            feature: "Premium Support",
            featureKey: "chat_feature",
            basic: false,
            pro: false,
            enterprise: true,
        }, {
            feature: "Self Hosted",
            featureKey: "chat_feature",
            basic: false,
            pro: false,
            enterprise: true,
        },
    ]

    const onChange = () => {
        console.log("onchange");
    }

    const columns: ColumnsType<IDataType> = [
        {
            title: "Features",
            dataIndex: "feature",
            key: "feature",
            width: "30%",
            render: (feature, record) => (
                <button className="btn-style-none">
                    <h5 className="title5">{feature}</h5>
                    <Paragraph className="title5" copyable style={{ fontSize: "13px" }}>{record.featureKey}</Paragraph>
                </button>),

        },
        {
            title: "Basic",
            dataIndex: "basic",
            key: "basic",
            width: "23.33%",
            render: (basic) => {
                console.log("recordBasic", basic);
                return (
                    <>
                        <Switch
                            defaultChecked={basic}
                            onChange={onChange}
                        />


                    </>
                )
            },
        },
        {
            title: "Pro",
            dataIndex: "pro",
            key: "pro",
            width: "23.33%",
            render: (pro) => (
                <>
                    <Switch
                        defaultChecked={pro}
                        onChange={onChange}
                    />
                </>
            ),
        },
        {
            title: "Enterprise",
            dataIndex: "enterprise",
            key: "enterprise",
            width: "23.33%",
            render: (enterprise) => (
                <>
                    <Switch
                        defaultChecked={enterprise}
                        onChange={onChange}
                    />
                </>
            ),
        },
    ]




    return (
        <>
            <MainLayout>
                <div className="container" >
                    <div className="plans-title">Plans</div>
                    <Button type="primary" className="btn-plus">
                        Add Plan <PlusCircleOutlined />
                    </Button>
                </div>
                <Table pagination={false} columns={columns} dataSource={data} />
            </MainLayout>

        </>
    )

}

export default Roles;