export const LOGIN_WITH_GOOGLE = "/auth/google/get"
export const MY_DETAILS = "/users/me"
export const MEMBERS = "/users"
export const FEATURES = "/features"
export const FEATURE = "/features/{feature-id}"
export const GET_ENVIRONMENTS = "/environments"
export const GET_ENVIRONMENT_BY_ID = "/environments/{environment-id}"
export const UPDATE_FEATURE_STATE = "/environments/{environment-id}/features/{feature-id}"
export const GET_END_ORGS = "/end-organizations"
export const GET_END_ORG = "/end-organizations/{endOrg-Id}"
export const UPDATE_END_ORG_FEATURE = "/end-organizations/{endOrg-id}/features/{feature-id}"

export const GET_ORG_CONFIG = "/organization/config"
