
// Write helper function for mixpanel and only enable in production

import mixpanel from 'mixpanel-browser';

const isMixpanelEnabled = process.env.REACT_APP_MIXPANEL_TOKEN !== undefined;

function InitAnalytics() {
    if (isMixpanelEnabled) mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN!);
}

function TrackEvent(eventName: string, props: any) {
    if (isMixpanelEnabled) mixpanel.track(eventName, props);
}

function IdentifyUser(userId: string, props: any) {
    if (isMixpanelEnabled) {
        mixpanel.identify(userId)
        if (props) SetUserProperties(props);
    };

}

function SetUserProperties(props: any) {
    if (isMixpanelEnabled) mixpanel.people.set(props);
}

const Anlaytics = { InitAnalytics, TrackEvent, IdentifyUser, SetUserProperties };

export default Anlaytics;