import { Spin } from 'antd';
import get from 'lodash/get';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginSuccess from './pages/auth/loginSuccess/loginSuccess';
import EnvironmentSettings from './pages/environmentSettings/environmentSettings';
import TargetAudience from './pages/targetAudience/TargetAudience';
import * as ROUTES from './router/routes';
import { IAppState } from './redux/reducers/indexReducer';
import launchbees from 'launchbees-js-sdk';
import { IUser } from './redux/reducers/userReducer';
import { IUserResponse } from './remote/types/response';
import Roles from './pages/Roles/Roles';
import mixpanel from 'mixpanel-browser';
import Anlaytics from './analytics/analytics';
import { loadOrgConfig } from './redux/actions/orgActions ';

/**
 * Screens
 */
const HomeScreen = lazy(() => import(/* webpackChunkName: "HomeScreen" */ './pages/home/homeScreen'));
const AddFeature = lazy(() => import(/* webpackChunkName: "AddFeature" */ './pages/addFeature/addFeature'));
const LoginScreen = lazy(() => import(/* webpackChunkName: "LoginScreen" */ './pages/auth/login/loginScreen'));
const SignupScreen = lazy(() => import(/* webpackChunkName: "SignupScreen" */ './pages/auth/signup/signupScreen'));
const CompleteProfileScreen = lazy(() => import(/* webpackChunkName: "CompleteProfileScreen" */ './pages/auth/completeProfile/completeProfileScreen'));
const NewLoginScreen = lazy(() => import(/* webpackChunkName: "NewLoginScreen" */ './pages/auth/newLogin/newLoginScreen'));
const ForgotScreen = lazy(() => import(/* webpackChunkName: "ForgotScreen" */ './pages/auth/forgotPassword/forgotScreen'));
const OrganisationScreen = lazy(() => import(/* webpackChunkName: "OrganisationScreen" */ './pages/organisations/organisationScreen'));
const OrganisationActivity = lazy(() => import(/* webpackChunkName: "OrganisationActivity" */ './pages/organisations/activity'));
const MyAccount = lazy(() => import(/* webpackChunkName: "MyAccount" */ './pages/myAccount'));
const Feature = lazy(() => import(/* webpackChunkName: "Feature" */ './pages/addFeature/addFeature'));

const AppRouter = () => {
	const user: IUserResponse = useSelector((state: IAppState) => get(state, 'userState.user'));
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (user) {
			const organization = get(user, 'organization');
			launchbees.identify({ organizationId: organization._id, organizationName: organization.name });
				Anlaytics.IdentifyUser(user._id, {
					"$email": user.email,
					"$name": user.name,
					"$organization": organization.name,
					"organization_id": organization._id,
				})
				dispatch(loadOrgConfig())
		}

	}, [user])

	return (
		<Spin spinning={isLoading}>
			<Suspense fallback={(<Spin spinning={true} />)} >
				<Router>
					<Routes>
						<Route path={ROUTES.HOME_SCREEN} element={<HomeScreen />} />
						<Route path={ROUTES.ENVIRONMENT_SETTINGS} element={<EnvironmentSettings />} />
						<Route path={ROUTES.LOGIN_SCREEN} element={<LoginScreen />} />
						<Route path={"/target-audience"} element={<TargetAudience />} />
						<Route path={ROUTES.SIGNUP_SCREEN} element={<SignupScreen />} />
						<Route path={ROUTES.COMPLETE_PROFILE_SCREEN} element={<CompleteProfileScreen />} />
						<Route path={ROUTES.NEW_LOGIN_SCREEN} element={<NewLoginScreen />} />
						<Route path={ROUTES.FORGOT_SCREEN} element={<ForgotScreen />} />
						<Route path={ROUTES.LOGIN_SUCCESS} element={<LoginSuccess />} />
						<Route path={ROUTES.ADD_FEATURE_SCREEN} element={<AddFeature />} />
						<Route path={ROUTES.ORGANISATIONS_SCREEN} element={<OrganisationScreen />} />
						<Route path={ROUTES.ORGANISATION_ACTIVITY_SCREEN} element={<OrganisationActivity />} />
						<Route path={ROUTES.MY_ACCOUNT_SCREEN} element={<MyAccount />} />
						<Route path={ROUTES.FEATURE} element={<AddFeature />} />
						<Route path={ROUTES.FEATURE_TARGETING} element={<AddFeature />} />
						<Route path={ROUTES.ROLES} element={<Roles />} />
					</Routes>
				</Router>
			</Suspense>
		</Spin>
	);
};

export default AppRouter;
